import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../graphql/fragments"

import { HeadingBasic } from "../components/heading/heading"
import { CoverFeatured } from "../components/cover-featured/cover-featured"
import { WpGroup, Stats, Carousel } from "../components/utils/render-functions"
import { CoverMain } from "../components/cover-main/cover-main"
import { AcfIconList } from "../components/acf-iconlist"
import { Breadcrumbs } from "../components/breadcrumbs/breadcrumbs"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const AboutDutaPertiwi = ({ location, pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDeltamas: file(relativePath: { eq: "imgDeltamas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      featured: file(relativePath: { eq: "img-featured-news-sample.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      overseas: file(relativePath: { eq: "mask.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgVideoCover: file(
        relativePath: { eq: "home/bg_home_cover-hero-video.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "about-bumi-serpong-damai") {
          title
          slug
          blocks {
            name
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreImageBlock
            ...CoreButtonBlock
            ...CoreShortcodeBlock
            ...CoreCoverBlock
            ...CoreGroupBlock
            ...AcfLabelValueBlock
            ...AcfGalleryBlock
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
          translation(language: ID) {
            title
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
            blocks {
              name
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreImageBlock
              ...CoreButtonBlock
              ...CoreShortcodeBlock
              ...CoreCoverBlock
              ...CoreGroupBlock
              ...AcfLabelValueBlock
              ...AcfGalleryBlock
            }
          }
        }
      }
    }
  `)

  const wordpress = data?.wordPress?.pageBy?.blocks
  const seo = data.wordPress.pageBy.seo

  const cover = wordpress && wordpress.find(x => x.name === "core/cover")

  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        description={seo?.opengraphDescription}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        fbTitle={seo?.opengraphTitle}
        fbDescription={seo?.opengraphDescription}
        url={location?.href}
        twitterTitle={seo?.twitterTitle || seo?.title}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={data?.wordPress?.pageBy?.title}
          img={
            cover
              ? cover.attributes.url
              : data.imgVideoCover.childImageSharp.fluid
          }
          alt={data.wordPress.pageBy.title}
          className="cover-md cover-detail"
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordpress?.map((item, i) => {
        switch (item.name) {
          case "core/group":
            if (item.innerBlocks.find(x => x.name === "acf/iconlist")) {
              return (
                <section key={`value-${i}`} className="pb-main">
                  <div className="container container-md">
                    <HeadingBasic
                      h2={
                        item.innerBlocks.find(x => x.name === "core/heading")
                          .attributes.content
                      }
                    />
                    <AcfIconList data={item} />
                  </div>
                </section>
              )
            }
            return (
              <div key={`group-${i}`}>
                <div className="pb-main">
                  <WpGroup data={item} />
                </div>
              </div>
            )
          case "core/paragraph":
            return (
              <div key={`paragraph-${i}`} className="container container-md">
                <div className={i > 1 ? "" : "py-main"}>
                  <HeadingBasic text={[item.attributes.content]} />
                </div>
              </div>
            )
          case "acf/labelvalues":
            if (wordpress[i + 1].name === "core/image") {
              return (
                <div key={`lablevalues-${i}`}>
                  <Stats data={item} />
                </div>
              )
            } else {
              return (
                <div key={`lablevalues-${i}`} className="pb-main">
                  <Stats data={item} />
                </div>
              )
            }
          case "core/cover":
            if (i > 0) {
              return (
                <div key={`cover-${i}`} className="pb-main">
                  <CoverFeatured
                    label="News"
                    img={
                      item.attributes.url || data.featured.childImageSharp.fluid
                    }
                    title={
                      item.innerBlocks[0] &&
                      item.innerBlocks[0].attributes.content
                    }
                    text={
                      item.innerBlocks[1] &&
                      item.innerBlocks[1].attributes.content
                        .split("<br>")
                        .join(" ")
                    }
                    btnText="Read More"
                    btnLink={`/about-us${item.innerBlocks[2].innerBlocks[0].attributes.url}`}
                  />
                </div>
              )
            } else {
              return <div key={`cover-${i}`}></div>
            }
          case "core/image":
            return (
              <img
                key={`image-${i}`}
                alt="History"
                src={item.attributes.url}
                className="pb-main"
                width="100%"
              />
            )
          case "acf/acfgallery":
            return (
              <div key={`innerblocks-carousel-${i}`} className="py-main">
                <Carousel data={item} />
              </div>
            )
          default:
            return <div key={`default-${i}`}></div>
        }
      })}
    </Layout>
  )
}

export default AboutDutaPertiwi
